import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { TrailguidePluginOld } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><strong parentName="p">{`Trail maps can be easily configured and embedded on a website, an article,
or in an app. They are interactive and allow the users to use full
functionality of Trailguide, but at the same time show exactly what you want`}</strong></p>
    <br />
    <p>{`The first plugin shows the Trail Trophy Enduro Race in St. Andreasberg,
Germany. An MTB event with six stages. The content is limited to the event,
so you only see the six stages, the transferes, a path where spectators can
watch the action, and event related point of interests are shown. All public
content of Trailguide is hidden.`}</p>
    <br />
    <p>{`The map is the open street map, with topographical details. The color is set
to grayscale. The map is interactive. Zoom and pan the map, mouse over trails
or point of interests shows short info box. Click on the trails to get to the
trail description.`}</p>
    <br />
    <p>{`The list shows the trails visible on the map. The height and width of the map
can be easily adjusted. The code for the iframe is short, like a URL and can
be placed in the code of the website or in an article, where the map should be
shown.`}</p>
    <TrailguidePluginOld code="K" map="cyclemap" hideList={false} gray={true} mdxType="TrailguidePluginOld" />
    <p>{`The second example shows a region in South Tirol, Italy. The map is google
terrain, zoomed out to show an overview over several villages and trails.
The main list is hidden. Click on the little arrow top left to unfold it.`}</p>
    <TrailguidePluginOld pos="12,46.6215,10.8898" map="google" mdxType="TrailguidePluginOld" />
    <p>{`The third example shows the official singletracks of the Mountainbike
Freiburg e.V. The more than 30 other trails in and around Freiburg are hidden.
Uphills (green) and a point of interest (Bikefestival Freiburg) are shown in
the map, that are not visible in public Trailgduide.`}</p>
    <br />
    <p>{`The height of the map is adjusted to fit to the vertical layout of the tracks.
The map is based on satellite photos.`}</p>
    <TrailguidePluginOld code="L" map="photo" height="1200" mdxType="TrailguidePluginOld" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      